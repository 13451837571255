import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="fit">
      <div className="content">
        <img className="logo" src="/shopencode-logo.svg" alt="" />
        <h1>
          <strong>SHOP</strong>ENCODE
        </h1>
        <p>We'll be back when the product is ready.</p>
        <a href="mailto:hello@shopencode.com">hello@shopencode.com</a>
      </div>
    </div>
  );
}

export default App;
